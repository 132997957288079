<template>
  <div>
    <!--    兑换    -->
    <div class="container">
      <div class="to-top">
        <a-avatar :size="52" style="background-color: inherit !important;cursor: pointer" @click="linkCus">
          <img alt="avatar" :src="require('@/assets/tel.png')"/>
        </a-avatar>
        <div>
          <div class="to-top-font">USDT -> TRX</div>
        </div>
      </div>
      <div class="to-top-content-title">
        <a-radio-group v-model="radioIndex" type="button" @change="radioChange">
          <a-radio :value="0">
            <div style="font-size: 14px">兑换TRX</div>
          </a-radio>
          <a-radio :value="1">
            <div style="font-size: 14px">使用说明</div>
          </a-radio>
        </a-radio-group>
        <div v-if="radioIndex === 0" class="cards-cus">
          <div class="box">
            <div class="cards-cus-font">输入您的USDT数量（最低1U）</div>
            <div style="margin-top: 16px">
              <a-input-number :min="1" :precision="0" placeholder="请输入您即将支付的数量" size="large" v-model="usdt">
                <template #append>
                  <div class="cards-cus-font">USDT</div>
                </template>
              </a-input-number>
            </div>
            <div class="cards-cus-font" style="margin-top: 16px">您将得到的TRX数量</div>
            <div style="margin-top: 16px">
              <a-input size="large" v-model="trx" readonly>
                <template #append>
                  <div class="cards-cus-font">TRX</div>
                </template>
              </a-input>
            </div>
            <div style="margin-top: 16px">
              <a-button type="primary" shape="round" style="width: 99%" @click="queryToTrx">计算</a-button>
            </div>
          </div>
        </div>
        <div v-if="radioIndex === 0" class="cards-cus">
          <div class="box">
            <div class="cards-cus-font">向此地址转账自动完成兑换</div>
            <div style="margin-top: 16px">
              <a-input size="large" v-model="address" readonly></a-input>
            </div>
            <div style="margin-top: 16px">
              <a-button type="primary" shape="round" style="width: 99%" @click="copyAddress">复制钱包地址</a-button>
            </div>
          </div>
        </div>

        <div v-if="radioIndex === 1" class="cards-cus">
          <div class="box">
            <div class="cards-cus-font" style="text-align: left !important;margin-top: 18px">
              <text style="background-color: yellow;">1：禁止使用交易所提现</text>
            </div>
            <div class="cards-cus-font" style="text-align: left !important;margin-top: 18px">
              <text style="background-color: yellow;">2: 只能使用去中心化钱包转账</text>
            </div>
            <div class="cards-cus-font" style="text-align: left !important;margin-top: 28px">付款USDT，自动给你TRX</div>

            <div class="cards-cus-font" style="text-align: left !important;margin-top: 48px">官方Telegram：@chau51</div>
            <div class="cards-cus-font" style="text-align: left !important;margin-top: 18px">官方邮箱:
              51danbao@gmail.com
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Message} from "@arco-design/web-vue";

const axios = require('axios');

export default {
  name: 'HomeView',
  components: {},
  data() {
    return {
      radioIndex: 0,
      usdt: null,
      trx: 0,
      address: 'TMxURRnpsCvNPhJcJjUBkrq92WaH3bdb51'
    }
  },
  methods: {
    queryToTrx() {
      if (!this.usdt) {
        return Message.error('输入您的USDT数量（最低1U）')
      }
      let url = 'https://api.front.51danbao.com/api/front/to/trx/info'
      axios.get(url).then(res => {
        if (res.status === 200 && res.data.code === 200) {
          this.trx = res.data.data.rate * this.usdt
          this.address = res.data.data.address
        } else {
          Message.error(res.data.message)
        }
      }).catch(err => {
        Message.error(err.data.message)
      })
    },
    copyAddress() {
      this.$copyText(this.address).then(function (e) {
        Message.success('复制成功')
      }, function (e) {
        Message.error('复制失败')
      })
    },
    linkCus() {
      window.open('https://t.me/chau51', '_blank')
    },
    radioChange(e) {
      this.radioIndex = e
    },
  }
}
</script>


<style lang="scss" scoped>
.container {
  max-width: 540px !important;
  min-width: 320px !important;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;
  margin-top: 30px;

  .cards-cus {
    width: 99%;
    max-width: 540px !important;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-top: 20px;
    padding: 24px;

    border: 1px solid #E8E8E8;
    border-radius: 10px;

    .box {
      width: 99%;

      .cards-cus-font {
        font-size: 18px;
        font-weight: 600;
      }
    }

  }

  .to-top-content-card {
    margin-top: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .to-top-content-title {
    margin-top: 30px;
    width: 99%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  .to-top {
    display: flex;
    justify-content: center;
    align-items: center;

    .to-top-font {
      margin-left: 15px;
      font-weight: 800;
      font-size: 32px;
    }
  }

}
</style>
