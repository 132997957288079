import {createRouter, createWebHistory} from 'vue-router'
import HomeIndex from '../views/home.vue'
import HomeView from '../views/home/index.vue'
import ToTrx from '../views/totrx/index.vue'
import batchQuery from '../views/batch/index.vue'
import Order from '../views/order/index.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeIndex,
        children: [
            {
                path: '/',
                name: 'HomeView',
                component: HomeView,
                meta: {
                    title: '首页',
                },
            },
            {
                path: '/batchQuery',
                name: 'batchQuery',
                component: batchQuery,
                meta: {
                    title: '批量查询',
                },
            },
            {
                path: '/order',
                name: 'order',
                component: Order,
                meta: {
                    title: '交易列表',
                },
            },
        ]
    }, {
        path: '/ToTrx',
        name: 'ToTrx',
        component: ToTrx,
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL), routes
})

export default router
