import request from "@/utils/request.js";

/**
 * 账户信息
 */
export function getAsset(params) {
    return request.get('/api/account/token_asset_overview', {params: params});
}

/**
 * 账户信息
 */
export function getAccount(params) {
    return request.get('/api/accountv2', {params: params});
}

/**
 * USDT记录
 */
export function queryUsdtOrders(params) {
    return request.get('/api/multi/search', {params: params});
}


/**
 * TRX记录
 */
export function queryTRXOrders(params) {
    return request.get('/api/new/transaction', {params: params});
}
