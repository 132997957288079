<template>
  <div>
    <!--    搜索框-->
    <div class="container">
      <h2 class="search-font">
        批量查询USDT地址余额（仅支持TRON地址）
      </h2>
      <div class="search">
        <a-textarea v-model="address" rows="8" :placeholder="'一行一个地址\n最大可填写100个\n仅支持填写T开头的地址'"/>
      </div>
      <div style="margin-top:20px;margin-bottom:60px;">
        <a-button size="large" type="primary" @click="batchQuery">查询</a-button>
      </div>
      <div style="margin-top:20px;margin-bottom:60px;" v-if="asset.length > 0">
        <div style="font-size: 14px;margin-bottom: 20px;font-weight: 600">
          总TRX：{{ totalTRX }} 总USDT：{{ totalUSDT }} 已查询地址：{{ asset.length }} 个
        </div>
        <a-table style="width: 90%;margin: 0 auto;" :columns="columns" :data="asset" :pagination="false"/>
      </div>
    </div>
  </div>
</template>
<script>
import {getAsset} from "@/api/tron";
import {Message} from '@arco-design/web-vue';

export default {
  name: 'batch',
  components: {},
  data() {
    return {
      address: '',
      asset: [],
      totalUSDT: 0,
      totalTRX: 0,
      columns: [{
        title: '地址',
        dataIndex: 'address',
      },
        {
          title: 'TRX',
          dataIndex: 'trx',
        },
        {
          title: 'USDT',
          dataIndex: 'usdt',
        },
        {
          title: '状态',
          dataIndex: 'status',
        },]
    }
  },
  methods: {
    batchQuery() {
      let addList = this.address.split(/\r?\n/)
      if (!addList || addList.length <= 0) {
        return Message.error('请填写地址')
      }
      const allNotStartWithT = addList.every((string) => !string.startsWith('T'));
      if (allNotStartWithT) {
        return Message.error('请填写正确的Tron地址')
      }
      this.asset = []
      this.totalUSDT = 0
      this.totalTRX = 0
      // 请求
      addList.forEach((itemAddress, index) => {
        let addressAcc = {
          index: index + 1,
          address: itemAddress,
          usdt: 0,
          trx: 0,
          status: '成功'
        }
        let params = {
          address: itemAddress,
        }
        getAsset(params).then(res => {
          if (res.data && res.data.length > 0) {
            res.data.forEach(item => {
              if (item.tokenId === 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t' && item.tokenAbbr === 'USDT') {
                addressAcc.usdt = item.balance / 1000000
                this.totalUSDT += addressAcc.usdt
              }
              if (item.tokenName === 'trx' && item.tokenAbbr === 'trx') {
                addressAcc.trx = item.assetInTrx
                this.totalTRX += addressAcc.trx
              }
            })
          }
          this.asset.push(addressAcc)
        }).catch(err => {
          addressAcc.status = '失败'
          Message.error(err.message)
        })
      })
    },
  }
}
</script>


<style lang="scss" scoped>
::v-deep(.arco-textarea) {
  min-height: 300px !important;
}

.cover {
  background-color: #FFFFFF;
  height: 313px;
  box-shadow: 0px 0px 9px #ddd;
  color: #1B5C93;
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  .item-font-one {
    font-size: 17px;
    margin-top: 15px;
    font-weight: 800;
  }

  .item-font-two {
    font-size: 21px;
    margin-top: 15px;
    font-weight: 800;
  }

  .item-font-three {
    font-size: 17px;
    margin-top: 10px;
    font-weight: 500;
  }
}

.cover:hover {
  color: #CCDAE7 !important;
}

.cover:hover:after {
  color: #1B5C93 !important;
}

.container {
  max-width: 1520px;
  min-width: 320px !important;

  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;

  .nav-item {
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #f5f9fc !important;

    .row {
      display: flex;
      justify-content: center;
      align-items: center;

      flex-wrap: wrap;
      margin-top: 52px;
      margin-bottom: 60px;
    }

    .col-md-3 {
      width: 730px;
      padding-right: 10px;
      margin-bottom: 90px;
    }

    .nav-item-cus {
      margin-top: 50px;
      margin-bottom: 30px;

      .grid-demo .arco-col {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 98px;
        line-height: 48px;
        text-align: center;
        background-color: #FFFFFF !important;
      }
    }

    .nav-font-price {
      margin-top: 52px;
      font-size: 20px;
      padding-top: 40px;
      font-weight: 600;
      color: #5c768d !important
    }

    .nav-font-info {
      margin-top: 12px;
      font-size: 16px;
      font-weight: 600;
      color: #9e9e9e !important
    }
  }

  .search {
    min-width: 320px !important;
    width: 70% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding-top: 20px;

    .input-search {
      width: 100%;
      height: 54px !important;

      .arco-btn {
        height: 52px !important;
        width: 106px !important;
      }
    }
  }

  .search-font {
    min-width: 320px !important;
    color: #5c768d !important;
    margin-top: 150px;
    font-size: 24px;
  }

  .home-header {
    margin-top: 30px;
    height: 65px;
    min-width: 1200px;

    display: flex;
    justify-content: space-between;

    .main-font {
      font-size: 32px;
    }

    .main-font:hover {
      color: #CCDAE7 !important;
    }

    .main-font:hover:after {
      color: inherit !important;
    }

    .main-url {
      font-size: 22px;
      cursor: pointer;
    }

    .main-url:hover {
      color: #CCDAE7 !important;
    }

    .main-url:hover:after {
      color: inherit !important;
    }

    .main-url-betch {
      font-size: 15px !important;
      margin-left: 20px !important;

      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      padding-bottom: 10px;
      cursor: pointer;
    }

    .main-url-betch:hover {
      color: #CCDAE7 !important;
    }

    .main-url-betch:hover:after {
      color: inherit !important;
    }

    .main-page-font {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      cursor: pointer;

      color: #1c5c93;
      font-weight: 700;
    }

    .main-page-font:hover {
      color: #CCDAE7 !important;
    }

    .main-page-font:hover:after {
      color: #1c5c93 !important;
    }

  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.header {
  flex: 0;
}

.content {
  flex: 1;
}

.footer {
  flex: 1;
  width: 100%;
  min-height: 212px;
  background-color: #587187;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding-left: 60px;

  .footer-email {
    margin-top: 80px;
    font-size: 24px !important;
    color: #FFFFFF;
    cursor: pointer;
  }

  .footer-email:hover {
    color: #CCDAE7 !important;
  }

  .footer-email:hover:after {
    color: #FFFFFF !important;
  }

  .footer-telegram {
    margin-top: 10px;
    font-size: 21px !important;
    color: #FFFFFF;
    cursor: pointer;
  }

  .footer-telegram:hover {
    color: #CCDAE7 !important;
  }

  .footer-telegram:hover:after {
    color: #FFFFFF !important;
  }
}
</style>
