import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ArcoVue from '@arco-design/web-vue';
import '@arco-design/web-vue/dist/arco.css';
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import Clipboard from 'vue-clipboard2'
import Vue3QrCode from 'vue3-qrcode';


import '@/assets/styles/main.css'
import '@/assets/styles/animation.css'


createApp(App)
    .use(Vue3QrCode)
    .use(ArcoVue)
    .use(ArcoVueIcon)
    .use(Clipboard)
    .use(store)
    .use(router)
    .mount('#app')
