<template>
  <div class="container">
    <div class="filter-container">
      <div style="font-size: 14px;text-align: left;font-weight: 600;">钱包地址:</div>
      <div style="font-size: 14px;text-align: left;margin-top: 10px;font-weight: 500;">
        {{ address }}
        <a-popover :content-style="{padding: '0'}" position="right">
          <icon-qrcode/>
          <template #content>
            <QRCODE :qr-text="address"/>
          </template>
        </a-popover>
      </div>
      <a-divider/>
      <div style="font-size: 14px;text-align: left;margin-top: 20px;font-weight: 600;">
        <div>TRX数量：{{ trx }}</div>
        <div class="margin-cu">USDT数量：{{ usdt }}</div>
        <div class="margin-cu">
          <a-tooltip
              content="发送USDT会消耗能量，一笔交易约消耗6.5万 能量，如果没有能量就会燃烧接近14trx来抵扣能量，向某些地址交易有时候可能会消耗双倍的能量，原因取决于接收方，接收方的地址的USDT=0，则就需要消耗双倍的能量;获得能量的方法只有两个:1:向别人购买 2:把自己的TRX资产抵押给区块链，每抵押5249TRX每天可以获得6.5万能量，获得能量以后可以自己使用也可以出售给他人."
              position="top">
            <icon-question-circle-fill/>
            <template #content>
              <div style='width:300px;font-size: 14px;'>
                <p style='font-size: 14px;'>发送USDT会消耗能量，一笔交易约消耗6.5万 能量，如果没有能量就会燃烧接近14trx
                  来抵扣能量，向某些地址交易有时候可能会消耗双倍的能量，原因取决于接收方，接收方的地址的USDT=0，则就需要消耗双倍的能量</p>
                <span style='font-size: 14px;'>获得能量的方法只有两个:</span>
                <span style='font-size: 14px;'>1:向别人购买 </span>
                <p style='font-size: 14px;'>
                  2:把自己的TRX资产抵押给区块链，每抵押5249TRX每天可以获得6.5万能量，获得能量以后可以自己使用也可以出售给他人</p>
              </div>
            </template>
          </a-tooltip>
          能量：{{ power1 }}/{{ power2 }}
        </div>
        <div class="margin-cu">激活时间：{{ jh ? jhTime : '未激活' }}</div>
        <div class="margin-cu">交易次数：{{ orderSize }}</div>
      </div>
      <a-divider/>
      <div class="row">
        <div class="col-md-3" style="margin-left: 10px;margin-top: 10px;">
          <a-radio-group type="button" style="width: 100%" v-model="switchAmount" @change="changeAmount">
            <a-radio style="width: 100%" value="usdt">USDT</a-radio>
            <a-radio style="width: 100%" value="trx">TRX</a-radio>
          </a-radio-group>
        </div>
        <div class="col-md-2" style="margin-left: 10px;margin-top: 10px;">
          <a-select style="width: 100%" placeholder="收支类型" v-model="income" @change="changeIncome">
            <a-option :value="0">全部</a-option>
            <a-option :value="1">接收</a-option>
            <a-option :value="2">转出</a-option>
          </a-select>
        </div>
        <div class="col-md-4" style="margin-left: 10px;margin-top: 10px;">
          <a-input-number v-model="min" :precision="3" placeholder="最小数量" :min="0" style="width: 154px;"
                          @change="changeMin"/>
          -
          <a-input-number v-model="max" :precision="3" placeholder="最大数量" :min="0" style="width: 154px;"
                          @change="changeMax"/>
        </div>
        <div class="col-md-4" style="margin-left: 10px;margin-top: 10px;">
          <a-range-picker show-time
                          :time-picker-props="{ defaultValue: ['00:00:00', '23:59:59'] }"
                          format="YYYY-MM-DD HH:mm:ss"
                          value-format="YYYY-MM-DD HH:mm:ss"
                          @ok="onOk"
          />
        </div>
        <div class="col-md-3" style="margin-left: 10px;margin-top: 10px;">
          <a-space style="width: 100%">
            <a-button type="primary" @click="clear">清空</a-button>
            <a-button type="primary" @click="queryOrdersSwitch">筛选</a-button>
          </a-space>
        </div>
      </div>
      <div style="font-size: 14px;font-weight: 500;margin-top: 20px;text-align: left;">共计{{ total }}条数据
        收入{{ incomePrice.toFixed(6) }} | 支出 {{ outPrice.toFixed(6) }}
      </div>
      <div style="font-size: 14px;font-weight: 500;margin-top: 30px;text-align: left;">
        <a-table :data="orders" column-resizable size="medium"
                 :loading="loading" :pagination="false"
                 style="width: 100%;min-width: 560px;">
          <template #columns>
            <a-table-column
                title="交易时间"
                align="center"
                :width="170">
              <template #cell="{record}">
                {{ record.block_ts }}
              </template>
            </a-table-column>

            <a-table-column
                title="交易对象"
                align="center"
                :width="336">
              <template #cell="{record}">
                {{ record.to_address }}
              </template>
            </a-table-column>

            <a-table-column
                title="币种"
                align="center"
                :width="70">
              <template #cell="{record}">
                {{ record.usdt }}
              </template>
            </a-table-column>

            <a-table-column
                title="交易数量"
                align="left"
                :width="120">
              <template #cell="{record}">
                <a-tag v-if="record.amount.indexOf('+') > -1" color="#198753" bordered style="border-radius: 5px;color: #FFFFFF;">{{ record.amount }}</a-tag>
                <a-tag v-else color="#DB3545" bordered style="border-radius: 5px;color: #FFFFFF;">{{ record.amount }}</a-tag>
              </template>
            </a-table-column>

            <a-table-column
                title="交易ID哈希"
                align="center"
                :ellipsis="true"
                :tooltip="{position: 'left'}">
              <template #cell="{record}">
                <div style="cursor: pointer;color: #0081ff;font-size: 12px;" @click="copy(record.transaction_id)">{{ record.transaction_id }}</div>
              </template>
            </a-table-column>

            <a-table-column
                title="区块高度"
                align="center"
                :width="100">
              <template #cell="{record}">
                {{ record.block }}
              </template>
            </a-table-column>
          </template>
        </a-table>

        <div style="margin-top: 30px;display: flex;justify-content: center;">
          <a-pagination :total="total" :current="page" :page-size="limit" show-page-size
                        @change="changePage" @page-size-change="changePageSize"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getAccount, getAsset, queryTRXOrders, queryUsdtOrders} from "@/api/tron";
import {Message} from '@arco-design/web-vue';
import QRCODE from '@/components/qrcode/index.vue'
import moment from 'moment';

export default {
  name: 'batch',
  components: {
    QRCODE
  },
  data() {
    return {
      address: '',
      switchAmount: 'usdt',
      income: 0,
      min: null,
      max: null,
      orders: [],
      usdt: 0,
      trx: 0,
      power1: 0,
      power2: 0,
      jh: 0,
      jhTime: 0,
      orderSize: 0,
      page: 1,
      start: 0,
      limit: 50,
      total: 0,
      incomePrice: 0,
      outPrice: 0,
      loading: false
    }
  },
  created() {
    this.address = this.$route.query.address;
    this.setTimeInit()
    this.queryBalance()
    this.batchQuery()
    this.queryOrders()
  },
  methods: {
    copy(record) {
      this.$copyText(record).then(function (e) {
        Message.success('复制成功')
      }, function (e) {
        Message.error('复制失败')
      })
    },
    setTimeInit() {
      let start = moment().subtract(24, 'hours');
      this.start_timestamp = start.valueOf()
      let end = moment();
      this.end_timestamp = end.valueOf()
    },
    clear() {
      this.setTimeInit()
      this.switchAmount = 'usdt'
      this.income = 0
      this.min = null
      this.max = null
      this.page = 1
      this.start = 0
      this.limit = 50
      this.total = 0
      this.queryOrders()
    },
    changePage(page) {
      this.page = page
      this.start = (page - 1) * this.limit
      if (this.switchAmount === 'usdt') {
        this.queryOrders()
      }
      if (this.switchAmount === 'trx') {
        this.queryTRXOrders()
      }
    },
    queryOrdersSwitch() {
      if (this.switchAmount === 'usdt') {
        this.queryOrders()
      }
      if (this.switchAmount === 'trx') {
        this.queryTRXOrders()
      }
    },
    changePageSize(pageSize) {
      this.page = 1
      this.start = 0
      this.limit = pageSize
      if (this.switchAmount === 'usdt') {
        this.queryOrders()
      }
      if (this.switchAmount === 'trx') {
        this.queryTRXOrders()
      }
    },
    queryOrders() {
      let params = {
        limit: this.limit,
        start: this.start,
        type: 'transfer',
        secondType: 20,
        start_timestamp: this.start_timestamp,
        end_timestamp: this.end_timestamp,
        token: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
        amount: null,
        relation: 'or',
        fromAddress: this.income === 2 ? this.address : this.income === 0 ? this.address : null,
        toAddress: this.income === 1 ? this.address : this.income === 0 ? this.address : null
      }
      if (this.min && this.max && this.max < this.min) {
        return Message.error('最大数量不可小于最小数量');
      }
      if (this.min && this.max) {
        params.amount = this.min + "," + this.max
      }
      if (!this.min && this.max) {
        params.amount = "0," + this.max
      }
      if (this.min && !this.max) {
        params.amount = this.min + ","
      }
      this.orders = []
      this.loading = true
      queryUsdtOrders(params).then(res => {
        console.log(res)
        this.total = res.rangeTotal
        this.incomePrice = 0
        this.outPrice = 0
        res.data.forEach(item => {
          let data = {
            block_ts: moment(item.date_created).format('YYYY-MM-DD HH:mm:ss'),
            amount: item.to_address === this.address ? '+' + item.amount / 1000000 : '-' + item.amount / 1000000,
            to_address: item.to_address === this.address ? item.from_address : item.to_address,
            usdt: 'USDT',
            transaction_id: item.hash,
            block: item.block,
            transaction_id_block: item.hash
          }
          this.orders.push(data)
          if (item.to_address === this.address) {
            this.incomePrice += item.amount / 1000000
          } else {
            this.outPrice += item.amount / 1000000
          }
        })
        this.loading = false
      }).catch(err => {
        this.loading = false
        Message.error(err.message)
      })
    },
    queryTRXOrders() {
      let params = {
        limit: this.limit,
        start: this.start,
        type: 'transfer',
        secondType: 'trx',
        start_timestamp: this.start_timestamp,
        end_timestamp: this.end_timestamp,
        amount: null,
        relation: 'or',
        fromAddress: this.income === 2 ? this.address : this.income === 0 ? this.address : null,
        toAddress: this.income === 1 ? this.address : this.income === 0 ? this.address : null
      }
      if (this.min && this.max && this.max < this.min) {
        return Message.error('最大数量不可小于最小数量');
      }
      if (this.min && this.max) {
        params.amount = this.min + "," + this.max
      }
      if (!this.min && this.max) {
        params.amount = "0," + this.max
      }
      if (this.min && !this.max) {
        params.amount = this.min + ","
      }
      this.orders = []
      this.loading = true
      queryUsdtOrders(params).then(res => {
        console.log(res)
        this.total = res.rangeTotal
        this.incomePrice = 0
        this.outPrice = 0
        res.data.forEach(item => {
          let data = {
            block_ts: moment(item.date_created).format('YYYY-MM-DD HH:mm:ss'),
            amount: item.to_address === this.address ? '+' + item.amount / 1000000 : '-' + item.amount / 1000000,
            to_address: item.to_address === this.address ? item.from_address : item.to_address,
            usdt: 'TRX',
            transaction_id: item.hash,
            block: item.block,
            transaction_id_block: item.hash
          }
          this.orders.push(data)
          if (item.to_address === this.address) {
            this.incomePrice += item.amount / 1000000
          } else {
            this.outPrice += item.amount / 1000000
          }
        })
        this.loading = false
      }).catch(err => {
        this.loading = false
        Message.error(err.message)
      })
    },
    batchQuery() {
      let params = {
        address: this.address
      }
      getAccount(params).then(res => {
        if (res.date_created === 0) {
          this.jh = 0
        } else {
          this.jh = 1
          this.jhTime = moment(res.date_created).format('YYYY-MM-DD HH:mm:ss');
        }
        this.orderSize = res.transactions
        this.power1 = res.bandwidth.energyRemaining
        this.power2 = res.bandwidth.energyLimit
      }).catch(err => {
        Message.error(err.message)
      })
    },
    changeAmount(e) {
      this.switchAmount = e
      if (this.switchAmount === 'usdt') {
        this.queryOrders()
      }
      if (this.switchAmount === 'trx') {
        this.queryTRXOrders()
      }
    },
    changeIncome(e) {
      this.income = e
      if (this.switchAmount === 'usdt') {
        this.queryOrders()
      }
      if (this.switchAmount === 'trx') {
        this.queryTRXOrders()
      }
    },
    changeMin(e) {
      this.min = e
      if (this.switchAmount === 'usdt') {
        this.queryOrders()
      }
      if (this.switchAmount === 'trx') {
        this.queryTRXOrders()
      }
    },
    changeMax(e) {
      this.max = e
      if (this.switchAmount === 'usdt') {
        this.queryOrders()
      }
      if (this.switchAmount === 'trx') {
        this.queryTRXOrders()
      }
    },
    onOk(dateString, date) {
      this.start_timestamp = moment(dateString[0]).valueOf()
      this.end_timestamp = moment(dateString[1]).valueOf()
      if (this.switchAmount === 'usdt') {
        this.queryOrders()
      }
      if (this.switchAmount === 'trx') {
        this.queryTRXOrders()
      }
    },
    queryBalance() {
      let params = {
        address: this.address,
      }
      getAsset(params).then(res => {
        if (res.data && res.data.length > 0) {
          res.data.forEach(item => {
            if (item.tokenId === 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t' && item.tokenAbbr === 'USDT') {
              this.usdt = item.balance / 1000000
            }
            if (item.tokenName === 'trx' && item.tokenAbbr === 'trx') {
              this.trx = item.assetInTrx
            }
          })
        }
      }).catch(err => {
        Message.error(err.message)
      })
    }
  }
}
</script>


<style lang="scss" scoped>
.table-row-error {
  background-color: #ffcccc;
}

.table-row-normal {
  background-color: #ccffcc;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));

  .col-md-2 {
    flex: 0 0 auto;
    width: 200px;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 220px;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 400px;
  }
}

.container {
  max-width: 1520px;
  min-width: 320px !important;

  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;

  .filter-container {
    padding: 20px;
    background-color: #f5f9fc !important;

    margin-top: 50px;
    margin-bottom: 60px;

    .margin-cu {
      margin-top: 5px;
    }
  }
}

.red-cell {
  background-color: red;
  padding: 10px;
  border-radius: 10px;
}

.red-green {
  background-color: green;
  padding: 10px;
  border-radius: 10px;
}
</style>
