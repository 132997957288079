<template>
  <vue3-qr-code :text="qrText" :size="200" :value="qrText"></vue3-qr-code>
</template>

<script>
import Vue3QrCode from 'vue3-qrcode';

export default {
  components: {
    Vue3QrCode
  },
  props: {
    qrText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  }
};
</script>
